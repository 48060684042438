
import {useEffect} from 'react'
import SectionHome from './components/section-home';
import SectionOffer from './components/section-about';
import SectionStudio from "./components/section-studio";
import SectionRegister from './components/section-register';
import SectionContact from './components/section-contact';


import './App.css';
import SectionServices from './components/section-services';


function App() {
  useEffect(() => {
    // Smooth scroll function
    function smoothScroll(target, duration) {
      const targetSection = document.querySelector(target);
      const targetPosition = targetSection.offsetTop;
      const startPosition = window.pageYOffset;
      const distance = targetPosition - startPosition;
      let startTime = null;

      function animation(currentTime) {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const run = ease(timeElapsed, startPosition, distance, duration);
        window.scrollTo(0, run);
        if (timeElapsed < duration) requestAnimationFrame(animation);
      }

      function ease(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
      }

      requestAnimationFrame(animation);
    }

    // Smooth scroll event listener for all <a> tags with href starting with #
    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach(link => {
      link.addEventListener('click', function(event) {
        event.preventDefault();
        const target = this.getAttribute('href');
        smoothScroll(target, 1000); // Adjust duration as needed
      });
    });

    // Reload page on window resize
    const handleResize = () => {
      window.location.reload();
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };


  }, []);
  return (
    <div className="container-fluied">
        <SectionHome/>
        <SectionOffer/>
        <SectionStudio/>
        <SectionServices/>
        {/* <SectionRegister contact={false}/> */}
        <SectionContact/> 
    </div>
  );
}

export default App;
