import React from 'react';
import bolt from "../assests/BOLT_02_WHITE.png";



const SectionContact = () => {
   
    return (
      <>
        {/* <div className="row section-contact mw-100 m-auto " id="contact">
            <div className="col-12 text-center"><img src={Logo7} className="m-auto about-logo"/></div>
            <div className="col-12 mt-5 mb-5">
                <div className="row  mw-100 m-auto align-items-center justify-content-center">
                    <div className="col-12 text-center section-contact-desc">
                <h1 className="teext-center text-white "><b>No funny business</b><br/><span>Just good people with good ideas</span>
                </h1>
                <img src={Sun} className="ml-5"/>
                </div></div>
            </div>

            <div className="col-12 text-center mt-5 mb-5">
                <p className="text-center text-white section-contact-email"><a href="mailto:info@funnyweather.studio">info@funnyweather.studio</a></p>
                <p className="section-contact-social text-white">Linkedin | <a href="https://www.instagram.com/funny_weather_films?igsh=MTd3Mnd1dTNkd2NxNw==" target="_blank">Instagram</a></p>
            </div>
            
            

        </div>
        <div className="row section-contact-tradmark mw-100 m-auto">
            <div className="col-12 text-center">
                <p >©2024</p>
            </div>


        </div> */}
        <div
          className="row section-contact mw-100 m-auto align-items-center"
          id="contact"
        >
          <div className="container custom-container">
            <div className="row mw-100 m-auto align-items-center">
              <div className="col-12 text-center">
                <div className="d-flex align-items-center mb-3 mb-lg-4">
                  <h2 className="heading">Contact.</h2>
                  {/* <img src={bolt} className="m-auto contact-bolt" /> */}
                </div>
              </div>
              <div className="col-lg-10 col-12">
                <p className="italic-sub-heading">
                  <i>funny weather</i> is here for conscious enterprises across any
                  field of impact, culture and entertainment, as well as for
                  great projects from independent storytellers and studios.
                </p>
                <p className="text">
                  Co-founded in 2024 by Lisa Cadwallader and Isla
                  Gordon-Crozier, we bring together a track record working with
                  streamers, platforms, NGOs, and brands - established,
                  emerging, or just getting started.
                </p>
                <p className="text">
                  A changing planet needs a change of stories. Say hello!
                </p>
              </div>
              <div className="col-lg-10 col-12">
                <div className="row">
                  <div className="col-lg-8 col-md-6 col-12">
                    <div className="mail-text">
                      <a
                        href="mailto:info@funnyweather.studio"
                        className="links"
                      >
                        info@funnyweather.studio
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="social-links">
                      <a
                        href="https://www.linkedin.com/company/funny-weather"
                        className="links mr-1"
                        target="_blank"
                      >
                        LinkedIn
                      </a>
                      <a
                        href="https://www.instagram.com/funny_weather_studio_/?utm_source=ig_web_button_share_sheet"
                        className="links ml-1"
                        target="_blank"
                      >
                        Instagram
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-fix">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12">
                <div className="">
                  <a href="" className="copyrigt-text">
                    ©funnyweather
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default SectionContact;