import React, { useEffect } from 'react';
import Example from './section-contact-modal';
import Cloud from '../assests/cloud7.png'

const SectionHome = () => {
  useEffect(() => {
    const dvd = document.getElementById('dvd');
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const maxSpeed = 3;
    const minSpeed = 1;
    let dvdSpeedX = Math.random() * (maxSpeed - minSpeed) + minSpeed;
    let dvdSpeedY = Math.random() * (maxSpeed - minSpeed) + minSpeed;

    function update() {
      const dvdLeft = dvd.offsetLeft;
      const dvdTop = dvd.offsetTop;

      if (dvdLeft + dvd.offsetWidth > screenWidth || dvdLeft < 0) {
        dvdSpeedX = -dvdSpeedX;
      }
      if (dvdTop + dvd.offsetHeight > screenHeight || dvdTop < 0) {
        dvdSpeedY = -dvdSpeedY;
      }

      dvd.style.left = dvdLeft + dvdSpeedX + 'px';
      dvd.style.top = dvdTop + dvdSpeedY + 'px';

      requestAnimationFrame(update);
    }

    requestAnimationFrame(update);
  }, [])
  return (

    <div class="home">
      <a class="logo" href='#about' id="dvd"></a>
      {/* <div className="col-12 pb-5 mb-5 text-center d-none"> <a href="#contact" className="home-s7"> <img src={Cloud} /> </a> </div>
      <div class="col-12 col-md-4 text-white text-center mb-5 d-none" ><a href="#register" className="home-register"> REGISTER</a> </div>
<div class="col-12 col-md-4 text-white text-center mb-5  d-none" ><a href="#about" className="home-about"> ABOUT</a> </div>
<div class="col-12 col-md-4 text-white text-center mb-5 contact  d-none" >
  <Example/> </div>
      <div className="col-12 text-center text-white d-none">
                <p >©2024</p>
            </div> */}

    </div>

  );
}

export default SectionHome;