import React from 'react';
import Logo from "../assests/logo-blue.png";
import cloud from "../assests/heli-cloud.gif";

const SectionOffer = () => {
    return (
      <div className="row about mw-100 m-auto align-items-center" id="about">
        <div className="col-12 text-center">
          {/* <img src={cloud} className="m-auto about-cloud" /> */}
          <img src={Logo} className="about-logo" />
          <p className="sub-heading">
            A temperature check on how we tell stories.
          </p>
          {/* <p className="mt-4 mb-4 darken-text "><i>funny weather</i> is a temperature check on how we tell stories.</p>
                <p className="mt-4 mb-4 darken-text about-desc1 text-center m-auto">An insight and production studio
                built for positive intervention to tell stories
                that look good, feel good and do good
all at the same time.</p> */}

          {/* <p className="mt-4 mb-4 darken-text about-desc2 text-center m-auto">Drawing a straight line between insight and craft - we connect modern
audiences to stories of a world they want to live in.</p>

                <p className="mt-4 mb-4 darken-text about-desc3 text-center m-auto">Developing content that inspires adaptation and resilience - we build instant
                relevance and longevity for conscious agendas.
</p>
                <p className="mt-4 mb-4 darken-text about-desc4 text-center m-auto">A changing planet needs a change of stories.
</p> */}
        </div>
        {/* <div className="col-12 text-center">
                <p className="text-center text-white about-email"><a href="mailto:info@funnyweather.studio">info@funnyweather.studio</a></p>
                <p className="about-tradmark">©2024</p>
            </div> */}
        <div className="col-lg-4 col-md-4 col-4">
          <div className="naigation-box text-center">
            <a href="#studio" className="navigation-link">
              studio
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-4">
          <div className="naigation-box text-center">
            <a href="#services" className="navigation-link">
              services
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-4">
          <div className="naigation-box text-center">
            <a href="#contact" className="navigation-link">
              contact
            </a>
          </div>
        </div>
      </div>
    );
}

export default SectionOffer;