import React from 'react';
import cloud from "../assests/Cloud_01_White.png";

const SectionStudio = () => {
    return (
      <div className="row studio mw-100 m-auto align-items-center" id="studio">
        <div className="container custom-container">
          <div className="row mw-100 m-auto align-items-center">
            <div className="col-12 text-center">
              <div className="d-flex align-items-center mb-4">
                <h2 className="heading">Studio.</h2>
                {/* <img src={cloud} className="m-auto about-cloud" /> */}
              </div>
            </div>
            <div className="col-lg-10 col-12">
              <p className="italic-sub-heading">
                <i>funny weather</i> is a planet positive insight and production studio
                telling stories that look good, feel good and do good - all at
                the same time.
              </p>
              <p className="text">
                Drawing a straight line between insight and craft, we connect
                modern audiences to stories of a world they want to live in.
              </p>
              <p className="text">
                We’re here to diversify your agenda without leaving your values
                and ethos behind, to use the power of stories to fuel growth and
                longevity that sustains profit and planet.
              </p>
              <p className="text">Reach out for a free temperature check.</p>
            </div>
          </div>
        </div>
        <div className="bottom-fix">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12 order-lg-1 order-md-1 order-2">
              <div className="">
                <a href="" className="copyrigt-text">
                  ©funnyweather
                </a>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-12 order-lg-2 order-md-1 order-1">
              <div className="row">
                <div className="col-lg-8 col-md-6 col-12">
                  <div className="mail-text">
                    <a href="mailto:info@funnyweather.studio" className="links">
                      info@funnyweather.studio
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="social-links">
                    <a
                      href="https://www.linkedin.com/company/funny-weather"
                      className="links mr-1"
                      target="_blank"
                    >
                      LinkedIn
                    </a>
                    <a
                      href="https://www.instagram.com/funny_weather_studio_/?utm_source=ig_web_button_share_sheet"
                      className="links ml-1"
                      target="_blank"
                    >
                      Instagram
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default SectionStudio;