import React from 'react';
import sun from "../assests/SUN_01_White.png";

const SectionServices = () => {
    return (
      <div
        className="row services mw-100 m-auto align-items-center"
        id="services"
      >
        <div className="container custom-container">
          <div className="row mw-100 m-auto align-items-center">
            <div className="col-12 text-center">
              <div className="d-flex align-items-center mb-3 mb-lg-4">
                <h2 className="heading">Services.</h2>
                {/* <img src={sun} className="m-auto service-sun" /> */}
              </div>
            </div>
            <div className="col-lg-10 col-12">
              <p className="italic-sub-heading">
                <i>funny weather</i> streamlines strategy, creative and production, and
                is designed to be modular depending on your needs and workflow.
              </p>
              <p className="text">
                Our insight discovery is tailored to explore audience strategy,
                communications frameworks and creative concepts, across the
                social and environmental landscapes.
              </p>
              <p className="text">
                Our production services are tailored to innovate impact across
                entertainment, documentary, social and photography.
              </p>
              <p className="text">Curated case studies available on request.</p>
            </div>
          </div>
        </div>
        <div className="bottom-fix">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12 order-lg-1 order-md-1 order-2">
              <div className="">
                <a href="" className="copyrigt-text">
                  ©funnyweather
                </a>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-12 order-lg-2 order-md-2 order-1">
              <div className="row">
                <div className="col-lg-8 col-12">
                  <div className="mail-text">
                    <a href="mailto:info@funnyweather.studio" className="links">
                      info@funnyweather.studio
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="social-links">
                    <a
                      href="https://www.linkedin.com/company/funny-weather"
                      className="links mr-1"
                      target="_blank"
                    >
                      LinkedIn
                    </a>
                    <a
                      href="https://www.instagram.com/funny_weather_studio_/?utm_source=ig_web_button_share_sheet"
                      className="links ml-1"
                      target="_blank"
                    >
                      Instagram
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default SectionServices;